<template>
<div class='debug' @click='startDebug'>Click here to generate a debug report and send it to Simpleset.</div>
</template>

<script>

import axios from 'axios'
import { apiPath } from '@/configuration/settings'

export default {
    name: 'Debug',
    props: ['authFailed', 'type', 'channel', 'error'],
    data: function() {
        return {
            debug: false,
            appAuthFailed: this.authFailed,
            appType: this.type,
            appChannel: this.channel,
            debugLog: {details:{lastError: this.error}},
            completed: 10,
            lastError: this.error,
            connLog: []
        }
    },
    methods: {
        displayDebug() {
            console.log(`Debug is ${this.debug}`);
            this.imDone();
        },
        getBrowser() {
            'use strict';

            var module = {
                options: [],
                header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
                dataos: [
                    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                    { name: 'Windows', value: 'Win', version: 'NT' },
                    { name: 'iPhone', value: 'iPhone', version: 'OS' },
                    { name: 'iPad', value: 'iPad', version: 'OS' },
                    { name: 'Kindle', value: 'Silk', version: 'Silk' },
                    { name: 'Android', value: 'Android', version: 'Android' },
                    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                    { name: 'Linux', value: 'Linux', version: 'rv' },
                    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
                ],
                databrowser: [
                    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                    { name: 'Safari', value: 'Safari', version: 'Version' },
                    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                    { name: 'Opera', value: 'Opera', version: 'Opera' },
                    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
                ],
                init: function () {
                    var agent = this.header.join(' '),
                        os = this.matchItem(agent, this.dataos),
                        browser = this.matchItem(agent, this.databrowser);

                    return { os: os, browser: browser };
                },
                matchItem: function (string, data) {
                    var i = 0,
                        j = 0,
                        regex,
                        regexv,
                        match,
                        matches,
                        version;

                    for (i = 0; i < data.length; i += 1) {
                        regex = new RegExp(data[i].value, 'i');
                        match = regex.test(string);
                        if (match) {
                            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                            matches = string.match(regexv);
                            version = '';
                            if (matches) { if (matches[1]) { matches = matches[1]; } }
                            if (matches) {
                                matches = matches.split(/[._]+/);
                                for (j = 0; j < matches.length; j += 1) {
                                    if (j === 0) {
                                        version += matches[j] + '.';
                                    } else {
                                        version += matches[j];
                                    }
                                }
                            } else {
                                version = '0';
                            }
                            return {
                                name: data[i].name,
                                version: parseFloat(version)
                            };
                        }
                    }
                    return { name: 'unknown', version: 0 };
                }
            };
            let e = module.init();

            this.logEntry('environment', `os.name: ${e.os.name}`);
            this.logEntry('environment', `os.version: ${e.os.version}`);
            this.logEntry('environment', `browser.name: ${e.browser.name}`);
            this.logEntry('environment', `browser.version: ${e.browser.version}`);
            this.logEntry('environment', `navigator.userAgent: ${navigator.userAgent}`);
            this.logEntry('environment', `navigator.appVersion: ${navigator.appVersion}`);
            this.logEntry('environment', `navigator.platform: ${navigator.platform}`);
            this.logEntry('environment', `navigator.vendor: ${navigator.vendor}`);
            this.imDone();
        },
        getScreen() {
            this.logEntry('screen', `screen.width: ${window.screen.width}`);
            this.logEntry('screen', `screen.height: ${window.screen.height}`);
            this.logEntry('screen', `window.innerWidth: ${window.innerWidth}`);
            this.logEntry('screen', `window.innerHeight: ${window.innerHeight}`);
            this.imDone();
        },
        getNetwork() {
            let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            let effectiveType = connection.effectiveType;
            let type = connection.type;

            this.logEntry('connection', `network.effectiveType: ${effectiveType}`);
            this.logEntry('connection', `network.type: ${type}`);
            this.logEntry('connection', `connection.downlink: ${connection.downlink}`);
            this.logEntry('connection', `connection.downlinkMax: ${connection.downlinkMax}`);
            this.logEntry('connection', `connection.rtt: ${connection.rtt}`);
            this.logEntry('connection', `connection.saveData: ${connection.saveData}`);
            this.imDone();

            function updateConnectionStatus() {
                console.log("Connection type changed from " + type + " to " + connection.effectiveType);
                type = connection.effectiveType;
                this.updateConn(`${type}->${connection.effectiveType}`);
            }
            connection.addEventListener('change', updateConnectionStatus);

        },
        // getMediaSupport() {
        //     // Just a test, need to add the actual codecs / configs we use
        //     console.log(`Testing Codecs ->`);
        //     console.log(`   MP3 @ 128 kbps ->`);
        //
        //     if ('mediaCapabilities' in navigator) {
        //         const audioFileConfiguration = {
        //             type : 'file',
        //             audio : {
        //                 contentType: "audio/mp3",
        //                 channels: 2,
        //                 bitrate: 132700,
        //                 samplerate: 5200
        //             }
        //         };
        //
        //         navigator.mediaCapabilities.decodingInfo(audioFileConfiguration).then(result => {
        //             console.log('      This configuration is ' +
        //                 (result.supported ? '' : 'not ') + 'supported, ' +
        //                 (result.smooth ? '' : 'not ') + 'smooth, and ' +
        //                 (result.powerEfficient ? '' : 'not ') + 'power efficient.')
        //         })
        //             .catch(() => {
        //                 console.log("decodingInfo error: " + audioFileConfiguration.contentType)
        //             });
        //     }
        // },
        getLocalNetwork() {
            const findLocalIp = (logInfo = true) => new Promise( (resolve, reject) => {
                window.RTCPeerConnection = window.RTCPeerConnection
                    || window.mozRTCPeerConnection
                    || window.webkitRTCPeerConnection;

                if ( typeof window.RTCPeerConnection == 'undefined' )
                    return reject('WebRTC not supported by browser');

                let pc = new RTCPeerConnection();
                let ips = [];

                pc.createDataChannel("");
                pc.createOffer()
                    .then(offer => pc.setLocalDescription(offer))
                    .catch(err => reject(err));
                pc.onicecandidate = event => {
                    if ( !event || !event.candidate ) {
                        // All ICE candidates have been sent.
                        if ( ips.length == 0 )
                            return reject('WebRTC disabled or restricted by browser');

                        return resolve(ips);
                    }

                    let parts = event.candidate.candidate.split(' ');
                    let [base,componentId,protocol,priority,ip,port,,type,...attr] = parts;
                    let component = ['rtp', 'rtpc'];

                    if ( ! ips.some(e => e == ip) )
                        ips.push(ip);

                    if ( ! logInfo )
                        return;

                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `component: ${component[componentId - 1]}`);
                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `protocol: ${protocol}`);
                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `priority: ${priority}`);
                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `ip: ${ip}`);
                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `port: ${port}`);
                    this.logEntry(`localCandidate.${base.split(':')[1]}`, `type: ${type}`);

                    if ( attr.length ) {
                        for(let i = 0; i < attr.length; i += 2)
                            this.logEntry(`localCandidate.${base.split(':')[1]}`, `${attr[i]}: ${attr[i+1]}`);
                    }
                    this.imDone();
                };
            } );
            findLocalIp();
        },
        getRemoteNetwork() {
            const findRemoteIp = (logInfo = true) => new Promise( (resolve, reject) => {
                window.RTCPeerConnection = window.RTCPeerConnection
                    || window.mozRTCPeerConnection
                    || window.webkitRTCPeerConnection;

                if ( typeof window.RTCPeerConnection == 'undefined' )
                    return reject('WebRTC not supported by browser');

                let pc = new RTCPeerConnection({iceServers:[{ urls: `stun:stun.kinesisvideo.ca-central-1.amazonaws.com:443` }]});
                let ips = [];

                pc.createDataChannel("");
                pc.createOffer()
                    .then(offer => pc.setLocalDescription(offer))
                    .catch(err => reject(err));
                pc.onicecandidate = event => {
                    if ( !event || !event.candidate ) {
                        // All ICE candidates have been sent.
                        if ( ips.length == 0 )
                            return reject('WebRTC disabled or restricted by browser');

                        return resolve(ips);
                    }

                    let parts = event.candidate.candidate.split(' ');
                    let [base,componentId,protocol,priority,ip,port,,type,...attr] = parts;
                    let component = ['rtp', 'rtpc'];

                    if ( ! ips.some(e => e == ip) )
                        ips.push(ip);

                    if ( ! logInfo )
                        return;

                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `component: ${component[componentId - 1]}`);
                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `protocol: ${protocol}`);
                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `priority: ${priority}`);
                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `ip: ${ip}`);
                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `port: ${port}`);
                    this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `type: ${type}`);

                    if ( attr.length ) {
                        for(let i = 0; i < attr.length; i += 2)
                            this.logEntry(`remoteCandidate.${base.split(':')[1]}`, `${attr[i]}: ${attr[i+1]}`);
                    }

                    this.imDone();
                };
            } );
            findRemoteIp();

        /*eslint-enable */
        },
        getPublicIP() {
            fetch('https://api.ipify.org/?format=json')
                .then(response => response.json())
                .then(data => { //console.log(`public.ip: ${data.ip}`);
                    this.logEntry('publicip', `public.ip: ${data.ip}`)
                })
                .catch(reason => {
                    // console.log(reason)
                    this.logEntry('publicip', `public.ip: ${reason}`)
                })
                .finally(this.imDone());

        },
        async getDeviceStatus() {
            // test for webcam and microphone and get a verbose error

            const getMedia = async (constraints) => {

                let stream = null;

                try {
                    stream = await navigator.mediaDevices.getUserMedia(constraints);
                    this.logEntry('usermedia', `getUserMedia.succeed: ${stream}`);
                } catch(err) {
                    this.logEntry('usermedia', `getUserMedia.error: ${err}`);
                } finally {
                    this.imDone();
                }

            }
            await getMedia({
                video: { width: { ideal: 1280 }, height: { ideal: 720 } },
                audio: true
            });
        },
        getSessionInfo() {
            this.logEntry('session', `session.authFailed: ${this.appAuthFailed}`);
            this.logEntry('session', `session.type: ${this.appType}`);
            this.logEntry('session', `session.channel: ${this.appChannel}`);
            this.imDone();
        },
        startDebug() {
            this.debug = true;
            console.log("Debug Started");
            this.displayDebug();
            this.getNetwork();
            this.getBrowser();
            this.getScreen();
            // this.getMediaSupport();
            this.getLocalNetwork();
            this.getRemoteNetwork();
            this.getPublicIP();
            this.getDeviceStatus();
            this.getSessionInfo();
            this.debug = false;
        },
        logEntry(category, msg) {
            if (!Array.isArray(this.debugLog['details'][category])) {
                this.debugLog['details'][category] = Array(msg);
            } else {
                this.debugLog['details'][category].push(msg);
            }
        },
        imDone() {
            this.completed = this.completed - 1;
            console.log(`${this.completed} tests left on 10 tests`);
            if (this.completed == 0) {
                this.debugLog['details']['connectionChanges'] = this.connLog;
                console.log("Debug Completed: " );
                let jsonOutput = JSON.stringify(this.debugLog);
                console.log(jsonOutput);
                alert("Click ok to send the following debug results: \n\n" + jsonOutput);
                axios.post(apiPath + '/teleHealth/' + this.channel + '/debug/', this.debugLog)
                    .then((response) => console.log(response))
                    .catch((error) => console.log(error))
            }
        },
        updateConn(newType) {
            this.connLog.push(newType);
        }
    },
    mounted() {
        // debug could be set with a url parameter ?
        if (this.debug) {
            this.displayDebug();
        }
    },
    // beforeDestroy() {
    // clear all objects we have created to ensure we are not polluting the browser
    // 	clearInterval(this.interval)
    // }
}
</script>

<style lang='scss'>
.debug {
    font-size: 16px;
    padding: 6px 12px;
    border-radius: 20px;
    background: rgba(#000000, 0.3);
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 15;
    color: $color-white;
    cursor: pointer;
}
</style>
