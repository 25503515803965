import 'webrtc-adapter'
import { debounce } from 'lodash'
import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk'
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc'
const clientId = 'client'
let signalingClient = null
let peerConnection = null
let dataChannel = null

// Get KVS Client
const getKVSClient = ({ region, accessKeyId, secretAccessKey, sessionToken }) => 
	new KinesisVideo({ region, accessKeyId, secretAccessKey, sessionToken, correctClockSkew: true })

// Get Signalling Channel ARN
const getChannelARN = async (kinesisVideoClient, { channelName }) => {
	const describeSignalingChannelResponse = await kinesisVideoClient
		.describeSignalingChannel({ ChannelName: channelName })
		.promise()
	const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN
	console.log('Channel ARN: ', channelARN)
	return channelARN
}

// Get Signalling Channel Endpoints
const getSignallingChannelEndpoints = async (kinesisVideoClient, Role, channelARN) => {
	const getSignalingChannelEndpointResponse = await kinesisVideoClient
		.getSignalingChannelEndpoint({
			ChannelARN: channelARN,
			SingleMasterChannelEndpointConfiguration: {
				Protocols: ['WSS', 'HTTPS'],
				Role
			}
		})
		.promise()	
	const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
		endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
		return endpoints
	}, {})
	console.log('Endpoints: ', endpointsByProtocol)
	return endpointsByProtocol
}

// Get KVS Signalling Client
const getKVSSignallingClient = (endpoint, { region, accessKeyId, secretAccessKey, sessionToken }) =>
	new KinesisVideoSignalingChannels({ region, accessKeyId, secretAccessKey, sessionToken, endpoint, correctClockSkew: true  })

// Get ICE Servers
const getIceServers = async (kinesisVideoSignalingChannelsClient, channelARN, { region }) => {
	const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
		.getIceServerConfig({ ChannelARN: channelARN })
		.promise()
	const iceServers = [{ urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` }]
	getIceServerConfigResponse.IceServerList.forEach(iceServer =>
		iceServers.unshift({
			urls: iceServer.Uris,
			username: iceServer.Username,
			credential: iceServer.Password
		})
	)
	console.log('ICE servers: ', iceServers)
	return iceServers
}

// Get Peer Connection
const getPeerConnection = iceServers =>
	new RTCPeerConnection({ iceServers, iceTransportPolicy: 'all' })

// Configure Peer Connection
const configurePeerConnection = (role, { remoteView, localStream }) => {
	peerConnection.addEventListener('icecandidate', ({ candidate }) => {
		if (candidate) signalingClient.sendIceCandidate(candidate, role === Role.MASTER ? clientId : null)
	})
	peerConnection.addEventListener('track', e => {
		remoteView.srcObject = e.streams[0]
		connectionStats()
	})
	localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream))
}

// Get Signaling Client
const getSignalingClient = (role, channelARN, channelEndpoint, systemClockOffset, { region, accessKeyId, secretAccessKey, sessionToken }) => {
	const params = {
		role,
		channelARN,
		channelEndpoint,
		region,
		credentials: { accessKeyId, secretAccessKey, sessionToken },
		systemClockOffset
	}
	if (role === Role.VIEWER) params.clientId = clientId
	return new SignalingClient(params)
}

// Configure Signaling Channel
const configureSignalingClient = (role, iceServers, options) => {
	signalingClient.on('open', async () => {
		console.log('Connected to signaling channel')
		if (!peerConnection || peerConnection.connectionState === 'new') {
			peerConnection = getPeerConnection(iceServers)
			configurePeerConnection(role, options)
			configureDataChannel(options)	
			setTimeout(async () => {
				// we have to give a bit of time before sending an offer
				// otherwise the master sends an offer immediately which messes up the viewer on the first load
				if (peerConnection.connectionState === 'new') {
					console.log('okay', peerConnection, peerConnection.state)
					const offer = await peerConnection.createOffer({
						offerToReceiveAudio: true,
						offerToReceiveVideo: true
					})
					await peerConnection.setLocalDescription(offer)
					signalingClient.sendSdpOffer(peerConnection.localDescription, role === Role.MASTER ? clientId : null)
				}
			}, 1000)
		}
	})
	signalingClient.on('sdpOffer', async (offer, senderClientId) => {
		if (senderClientId === 'AWS_DEFAULT_SINGLE_MASTER') return
		peerConnection.close()
		peerConnection = getPeerConnection(iceServers)
		configurePeerConnection(role, options)
		configureDataChannel(options)	
		await peerConnection.setRemoteDescription(offer)
		const answer = await peerConnection.createAnswer({
			offerToReceiveAudio: true,
			offerToReceiveVideo: true,
		})
		await peerConnection.setLocalDescription(answer)
		signalingClient.sendSdpAnswer(peerConnection.localDescription, role === Role.MASTER ? clientId : null)
	})
	signalingClient.on('sdpAnswer', async (answer, senderClientId) => {
		//console.log('here', senderClientId, answer, peerConnection)
		if (senderClientId === 'AWS_DEFAULT_SINGLE_MASTER') return
		console.log('Received sdp answer')
		await peerConnection.setRemoteDescription(answer)//.catch(() => location.reload())
	})
	signalingClient.on('iceCandidate', (candidate, senderClientId) => {
		if (senderClientId === 'AWS_DEFAULT_SINGLE_MASTER') return
		console.log('Received ice candidate')
		peerConnection.addIceCandidate(candidate)
	})
	signalingClient.on('close', () => {
		console.log('Disconnected from signaling channel')
		if (peerConnection && peerConnection.connectionState !== 'closed') signalingClient.open()
	})
	signalingClient.on('error', options.onErr)
}

// Configure Data Channel
const configureDataChannel = ({ onRemoteDataMessage }) => {
	dataChannel = peerConnection.createDataChannel('kvsDataChannel')
	peerConnection.ondatachannel = e => e.channel.onmessage = onRemoteDataMessage
}

// Get connection stats
const connectionStats = debounce(async () => {
	const getCandidateIds = stats => {
		let ids = {}
		stats.forEach(report => {
			if (
				report.type === 'candidate-pair' &&
				report.nominated &&
				report.state === 'succeeded'
			) {
				ids = {
					local: report.localCandidateId,
					remote: report.remoteCandidateId
				}
			}
		})
		return ids
	}
	const getCandidateInfo = (stats, candidateId) => {
		let info = null
		stats.forEach(report => {
			if (report.id === candidateId) info = report
		})
		return info
	}
	const stats = await peerConnection.getStats(null)
	const ids = getCandidateIds(stats)
	const local = getCandidateInfo(stats, ids.local)
	const remote = getCandidateInfo(stats, ids.remote)
	if (!local || !remote) return
	console.log(`LOCAL: ${local.ip}:${local.port} ${local.candidateType}`)
	console.log(`REMOTE: ${remote.ip}:${remote.port} ${remote.candidateType}`)
}, 1000)

// Send Data Channel Message
export const sendMessage = message => {
	if (dataChannel) try {
		dataChannel.send(message)
	} catch (e) { console.log('Could not send message') }
}

// Rock and Roll
export const start = async (options, role) => {
	try {
		const kinesisVideoClient = getKVSClient(options)
		const channelARN = await getChannelARN(kinesisVideoClient, options)
		const endpointsByProtocol = await getSignallingChannelEndpoints(kinesisVideoClient, role, channelARN)
		const kinesisVideoSignalingChannelsClient = getKVSSignallingClient(endpointsByProtocol.HTTPS, options)
		const iceServers = await getIceServers(kinesisVideoSignalingChannelsClient, channelARN, options)
		signalingClient = getSignalingClient(role, channelARN, endpointsByProtocol.WSS, kinesisVideoClient.config.systemClockOffset, options) 
		configureSignalingClient(role, iceServers, options)
		signalingClient.open()
		return () => {
			signalingClient.close()
			if (peerConnection) peerConnection.close()
			signalingClient = null
			peerConnection = null
			dataChannel = null
		}
	} catch (err) {
		console.log(err)
		options.onErr(err)
	}
}
