<template>
	<div class='loading'>
		<div class='spinner' />
	</div>
</template>

<script>
export default {
	name: 'Loading',
	computed: {}
}
</script>

<style lang='scss'>
.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
	pointer-events: none;

	.spinner {
		background-color: lighten(#2472ab, 10%);
		animation: sk-rotateplane 1.2s infinite ease-in-out;
		width: 48px;
		height: 48px;
	}
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
</style>