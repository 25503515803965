<template>
<div class='timer'>{{minutes}}:{{seconds}}</div>
</template>

<script>
export default {
	name: 'Timer',
	data: function() {
		return {
			interval: null,
			timer: 0,
			minutes: null,
			seconds: null 
		}
	},
	methods: {
		tick() {
			this.minutes = parseInt(this.timer / 60, 10)
			this.seconds = parseInt(this.timer % 60, 10)
			this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes
			this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds
			this.timer++
		}
	},
	mounted() {
		this.interval = setInterval(this.tick, 1000)
		this.tick()
	},
	beforeDestroy() {
		clearInterval(this.interval)
	}
}
</script>

<style lang='scss'>
.timer {
	font-size: 16px;
	padding: 6px 12px;
	border-radius: 20px;
	background: rgba(#000000, 0.3);
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 15;
	color: $color-white;
}
</style>
