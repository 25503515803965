<template>
<div class='consent'>
	<transition name='dialog'><div v-if='step === 1'>
		<div>
			<header>Consent Required</header>
			<div class='body'>
				<p>Your care provider should use all reasonable means to protect against risks inherent to all electronic communication and to ensure the security and confidentiality of information transmitted during the TeleHealth session.</p>
				<ul>
					<li>All audio and video are end-to-end encrypted for your security and privacy.</li>
					<li>No audio or video will be recorded or stored by the TeleHealth system.</li>
				</ul>
				<p><b>Risks:</b>  Even after all reasonable efforts are taken to protect the privacy and security of electronic communications, it may not be possible to completely secure electronic information. Risks include, but are not limited to:</p>
				<ul>
					<li>Employers and online services may have a legal right to inspect and keep electronic communications that pass through their system.</li>
					<li>Electronic communications can be forwarded, intercepted, circulated, stored, or even changed without the knowledge or permission of the involved parties.</li>
					<li>Use of electronic communications to discuss sensitive information can increase the risk of such information being disclosed to third parties.</li>
				</ul>
				<p>I understand and accept the risks associated with the use of electronic communications as outlined above.</p>
			</div>
			<div class='submit' v-touch:tap='() => step++'>Yes, I Consent</div>
		</div>
	</div></transition>
	<transition name='dialog'><div v-if='step === 2'>
		<div>
			<header>Get Ready...</header>
			<div class='body'>
				<p>Your TeleHealth session is about to start...</p>
				<ul>
					<li>When the session starts, do not click the back button or navigate away from the page until after you are done.</li>
					<li>Make sure you are in a private and quiet place with no distractions.</li>
				</ul>
				<div class='videoWrapper'>
					<div>
						<video ref='localView' autoplay playsinline muted @play='videoWorks = true' />
					</div>
					<p v-if='videoWorks' class='videoWorks'>Looking good! 😁</p>
				</div>
			</div>
			<div class='submit' v-touch:tap='submit' :class='{ disabled: !videoWorks }'>Begin</div>
		</div>
	</div></transition>
</div>
</template>

<script>
export default {
	name: 'Consent',
	props: ['localStream'],
	data: () => ({ step: 1, videoWorks: false }),
	computed: {},
	methods: {
		submit() {
			this.$emit('consent', this.stream)
		}
	},
	watch: {
		step(value) {
			if (value === 2) this.$emit('startStream')
		},
		localStream(value) {
			if (value) this.$refs.localView.srcObject = value
		}
	},
	beforeDestroy() {
		this.$refs.localView.pause()
	}
}
</script>

<style lang='scss'>
.consent {
	width: 100%;
	height: 100%;
	position: relative;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		overflow: scroll;
	}

	> div > div {
		background: $color-white;
		overflow: hidden;

		header {
			font-size: 20px;
			color: #2472ab;
			text-align: center;
			padding: 30px 0;
			margin-bottom: 30px;
			background: $color-neutral-accent;
		}

		.body {
			padding: 0 30px;
			p {
				margin: 0;
				padding: 0;
				padding-bottom: 20px;
				font-size: 16px;
				line-height: 22px;
				color: #666;
			}
			ul {
				margin: 0;
				padding-bottom: 20px;
				font-size: 16px;
				line-height: 22px;
				color: #666;
			}

			b { color: $color-primary-accent; }

			.videoWorks {
				font-weight: bold;
				color: $color-success;
				margin-right: auto;
				margin-left: 0;
			}

			.videoWrapper {
				display: flex;
				align-items: center;

				> div {
					width: 120px;
					height: 120px;
					border-radius: 50%;
					overflow: hidden;
					background: $color-black;
					transform: translate3d(0,0,0);
					position: relative;
					flex-basis: 120px;
					margin: 10px 30px 30px auto;

					&:only-child { margin: 10px auto 30px auto; }

					video {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.submit {
			padding: 30px 0;
			font-size: 20px;
			color: #fff;
			text-align: center;
			background: $button-gradient-primary;
			cursor: pointer;
			margin: 10px 30px 30px 30px;
			border-radius: 2px;

			&.disabled {
				background: $color-neutral-silver;
				color: $color-neutral-shadow;
				border: 1px solid $color-neutral-shadow;
				pointer-events: none;
			}
		}
	}

	@media screen and (min-width: 800px) {
		> div > div {
			width: 60%;
			border-radius: 6px;
			box-shadow: $box-shadow-lifted;
			margin: auto;
			max-width: 650px;
		}
	}

	@media screen and (max-width: 799px) {
		> div > div {
			margin: 0 0 auto 0;
			min-height: 100%;
		}
	}
}
</style>