<template>
<div id='app' :class='{ sideBySideLayout: layout, nestedLayout: !layout }'>
	<template v-if='consentRequired'>
		<transition name='fade'>
			<Consent v-if='!error' @consent='consent' @startStream='initializeLocalStream' :localStream='localStream'  />
		</transition>
	</template>
	<template v-else>
		<Timer />
		<div v-if='layout && !sessionEnded' class='backDrop' />
		<div class='localView' :class='{ active: localActive }'>
			<div class='outer'>
				<div class='hangUp' v-touch:tap='() => confirmHangUp = true' />
				<div class='toggleLayout' v-touch:tap='() => layout = !layout' />
				<div class='inner'>
					<video ref='localView' autoplay playsinline muted @play='onLocalPlay' />
				</div>
			</div>
		</div>
		<div class='remoteView' :class='{ active: remoteActive }'>
			<video ref='remoteView' autoplay playsinline @play='onRemotePlay' />
		</div>
		<div class='toggleAudio' v-touch:tap='toggleAudio' :class='{ muted: !audioEnabled }'>
			<img v-if='audioEnabled' src='svg/mic.svg' />
			<img v-else src='svg/mic-slash.svg' />
		</div>
		<div class='toggleVideo' v-touch:tap='toggleVideo' :class='{ muted: !videoEnabled }'>
			<img v-if='videoEnabled' src='svg/video.svg' />
			<img v-else src='svg/video-slash.svg' />
		</div>
		<transition name='fade'>
			<div v-if='sessionEnded' class='sessionEnded'><span>The session has ended.  You may close this window.</span></div>
		</transition>
		<transition name='fade'>
			<div v-if='viewerLeftChat' class='spinnerMessage'><span>Your client has left the chat.</span></div>
		</transition>
		<transition name='fade'>
			<div v-if='waitingToStart' class='spinnerMessage'><span>Waiting for your client...</span></div>
		</transition>
		<transition name='fadeZoom'>
			<ConfirmHangUp 
				v-if='confirmHangUp' 
				@cancel='confirmHangUp = false'
				@submit='endSession'
			/>
		</transition>
	</template>
	<transition name='fade'>
		<div v-if='error' class='error'>Unable to start TeleHealth</div>
	</transition>
	<transition name='fadeZoom'>
		<Loading v-if='loading' />
	</transition>
	<transition name='fade'>
	<Debug v-if='error' :authFailed='authFailed' :type='type' :channel='channel' :error='error'></Debug>
	</transition>
</div>
</template>

<script>
import 'webrtc-adapter'
import { Role } from 'amazon-kinesis-video-streams-webrtc'
import { Howl, Howler } from 'howler'
import { start, sendMessage } from '@/rtc'
import { api } from '@/api'
import Loading from '@/components/Loading'
import Consent from '@/components/Consent'
import Timer from '@/components/Timer'
import Debug from '@/components/Debug'
import ConfirmHangUp from '@/components/ConfirmHangUp'

export default {
	name: 'TeleHealth',
	components: { Loading, Consent, Timer, ConfirmHangUp, Debug },
	data: () => ({
		debug: process.env.VUE_APP_DEBUG || false,
		error: false,
		consentRequired: false,
		type: null,
		localStarted: false,
		remoteStarted: false,
		sessionEnded: false,
		sessionPending: true,
		viewerLeft: false,
		confirmHangUp: false,
		layout: false,
		localStream: null,
		audioEnabled: true,
		videoEnabled: true,
		plop: new Howl({ src: ['/mp3/plop.mp3'] }),
		whoosh: new Howl({ src: ['/mp3/whoosh.mp3'] }),
		authFailed: false,
		channel: false,
		stopAll: null,
		keepFresh: null 
	}),
	computed: {
		localActive() {
			return (
				this.localStarted &&
				!this.sessionEnded
			)
		},
		remoteActive() {
			return (
				this.remoteStarted && 
				!this.sessionEnded
			)
		},
		viewerLeftChat() {
			return (
				!this.sessionEnded && 
				this.viewerLeft && 
				this.type === 'master'
			)
		}, 
		waitingToStart() {
			return (
				this.type === 'master' && 
				this.sessionPending === true && 
				!this.sessionEnded
			)
		},
		loading() {
			return (
				(!this.localStarted || !this.remoteStarted) &&
				!this.sessionEnded &&
				!this.consentRequired && 
				!this.error
			)
		}
	},
	methods: {
		onLocalPlay() {
			if (!this.localStarted) this.plop.play()
			this.localStarted = true
		},
		onRemotePlay() {
			this.remoteStarted = true
			this.sessionPending = false
			this.viewerLeft = false
			clearInterval(this.keepFresh)
		},
		onRemoteDataMessage(e) {
			if (e.data === 'SESSIONENDED') { 
				this.sessionEnded = true
				this.stopAll()
			} else if (e.data === 'VIEWERLEFTCHAT') {
				this.remoteStarted = false
				this.viewerLeft = true
			}
		},
		getChannel() {
			const urlParams = new URLSearchParams(window.location.search)
			this.channel = urlParams.get('channel');
			return this.channel;
		},
		toggleAudio() {
			this.localStream.getAudioTracks()[0].enabled = !(this.localStream.getAudioTracks()[0].enabled)
			this.audioEnabled = !this.audioEnabled
		},
		toggleVideo() {
			this.localStream.getVideoTracks()[0].enabled = !(this.localStream.getVideoTracks()[0].enabled)
			this.videoEnabled = !this.videoEnabled
		},
		endSession() {
			this.sessionEnded = true
			this.confirmHangUp = false
			if (this.type === 'master') sendMessage('SESSIONENDED')
			else if (this.type === 'viewer') sendMessage('VIEWERLEFTCHAT')
			this.stopAll()
		},
		hangUp() {
			this.sessionEnded = true
			this.whoosh.play()
			setTimeout(this.endSession, 1000)
		},
		async consent(localStream) {
			const channel = this.getChannel()
			this.consentRequired = false
			this.localStream = localStream
			await api.touch('consent', { channel })
			this.fetchCredentials()
		},
		async fetchCredentials() {
			const channel = this.getChannel()
			const [err, result] = await api.touch('fetchCredentials', { channel })
			if (err && err.statusCode === 403) this.consentRequired = true
			else if (err) {
				this.error = err;
				this.authFailed = true;
			}
			else if (!err) {
				this.initializeWebRTC(result.role, channel, result.credentials)
			}
		},
		async initializeLocalStream() {
			const constraints = {
				video: { width: { ideal: 1280 }, height: { ideal: 720 } },
				audio: true
			}
			try {
				this.localStream = await navigator.mediaDevices.getUserMedia(constraints)
				return this.localStream
			} catch (e) {
				alert('There was an error accessing your camera / mic: ' + e.name)
				this.error = e.name;
			}
		},
		async initializeWebRTC(type, channelName, { accessKeyId, secretAccessKey, sessionToken }) {
			this.type = type
			const remoteView = this.$refs.remoteView
			const localStream = this.localStream || await this.initializeLocalStream()
			this.$refs.localView.srcObject = localStream
			const options = {
				region: 'ca-central-1',
				channelName,
				accessKeyId,
				secretAccessKey,
				sessionToken,
				localStream,
				remoteView,
				onRemoteDataMessage: this.onRemoteDataMessage,
				onErr: (err) => this.error = err
			}
			//window.addEventListener('beforeunload', this.endSession)
			let fn
			if (type === 'master') fn = async () => this.stopAll = await start(options, Role.MASTER)
			else if (type === 'viewer') fn = async () => this.stopAll = await start(options, Role.VIEWER)
			await fn()
			this.keepFresh = setInterval(async () => {
				this.stopAll()
				await fn()
			}, 1000 * 60 * 5) // every five minutes refresh 
		}
	},
	mounted() {
		this.fetchCredentials()
		// howler should be initialized only after first event on page
		Howler.volume(.6)
	}
}
</script>

<style lang='scss'>
@import '/assets/scss/base';
#app {
	width: 100%;
	height: 100%;
	background: $color-primary;

	&.nestedLayout {

		.remoteView {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			opacity: 0;
			transform: scale(1.06);
			transition: opacity 500ms linear,
						transform 1000ms cubic-bezier(0.23, 1, 0.32, 1);

			&.active {
				transform: scale(1);
				opacity: 1;
			}
		}

		.localView {
			position: absolute;
			width: 20%;
			min-width: 60px;
			max-width: 200px;
			position: absolute;
			bottom: 10px;
			right: 60px;
			z-index: 10;

			.outer {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding-top: 100%;
				opacity: 0;
				transform: translateY(100%);
				transition: opacity 500ms linear,
							transform 1000ms cubic-bezier(0.23, 1, 0.32, 1);

				.inner {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					overflow: hidden;
					border: 2px solid $color-white;
					transform: translate3d(0,0,0);
				}

				.hangUp {
					top: 50%;
					left: -50px;
					margin-top: -30px;
				}

				.toggleLayout {
					top: 50%;
					right: -50px;
					margin-top: -30px;

					&:before {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 15px;
						height: 15px;
						margin-top: -7.5px;
						margin-left: -16px;
						background: $color-white;
						border-radius: 2px;
						content: '';
					}

					&:after {
						position: absolute;
						top: 50%;
						right: 50%;
						width: 15px;
						height: 15px;
						margin-top: -7.5px;
						margin-right: -16px;
						background: $color-white;
						border-radius: 2px;
						content: '';
					}		
				}
			}

			&.active > div {
				opacity: 1;
				transform: translateY(0);
			}
		}

	}

	&.sideBySideLayout {
		position: relative;
		display: flex;

		.backDrop {
			position: absolute;
			top: 25%;
			left: 0;
			width: 100%;
			height: 50%;
			background: $color-black;
			z-index: 1;
		}

		.remoteView {
			position: absolute;
			top: 25%;
			left: 0;
			width: 50%;
			height: 50%;
			opacity: 0;
			transition: opacity 500ms linear;
			z-index: 2;

			&.active { opacity: 1; }
		}

		.localView {
			position: absolute;
			top: 25%;
			right: 0;
			width: 50%;
			height: 50%;
			opacity: 0;
			transition: opacity 500ms linear;
			z-index: 3;

			&.active { opacity: 1; }

			.outer, .inner { width: 100%; height: 100%; }

			.hangUp {
				bottom: -30px;
				left: -30px;
			}

			.toggleLayout {
				top: -30px;
				left: -30px;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 30px;
					height: 20px;
					margin-top: -10px;
					margin-left: -15px;
					background: $color-white;
					border-radius: 2px;
					content: '';
				}

				&:after {
					position: absolute;
					top: 50%;
					right: 50%;
					width: 10px;
					height: 10px;
					margin-top: -2px;
					margin-right: -13px;
					background: $color-primary-accent;
					border-radius: 50%;
					content: '';
				}						
			}
		}

	}

	video {
		position: absolute;
		top: 0;
		left: 0;
		background: $color-black;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.toggleAudio, .toggleVideo, .toggleLayout, .hangUp {
		position: absolute;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		cursor: pointer;
		z-index: 10;
		border: 2px solid $color-white;
	}

	.hangUp {
		background: $color-alert;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			margin-top: -10px;
			margin-left: -10px;
			background: $color-white;
			border-radius: 2px;
			content: '';
		}
	}

	.toggleLayout {
		background: $color-primary-accent;
	}

	.toggleAudio, .toggleVideo {
		background: $color-black;
		top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.muted { background: $color-accent; }
		img {
			width: 24px;
			height: 24px;
			color: $color-white;
		}
	}

	.toggleAudio { right: 10px; }
	.toggleVideo { right: 68px; }

	.sessionEnded, .spinnerMessage, .error {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-white;
		z-index: 4;
		pointer-events: none;

		span {
			display: block;
			padding: 10px 20px;
			border-radius: 20px;
			background: $color-primary;
		}
	}

	.spinnerMessage { margin-top: 60px; }

}
</style>
