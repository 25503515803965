import { buildApi } from './factory'

const routes = {
	get: {
		fetchCredentials: '/teleHealth/${channel}/credentials'
	},
	post: {},
	put: {
		consent: '/teleHealth/${channel}/consent'
	},
	delete: {}
}

export const api = buildApi(routes)

