import axios from 'axios'
import { isPlainObject } from 'lodash'
import { fill, diff, toAsync } from './helpers'
import { apiPath } from '@/configuration/settings'

const CancelToken = axios.CancelToken

const getAxiosInstance = (baseUrl = '') => {
	return axios.create({
		baseURL: apiPath + baseUrl,
		responseType: 'json',
		withCredentials: true,
		transformResponse: res => {
			if (res && res.status === 'success') return res.body
			else if (res && res.status === 'error') return res.error
			else return res
		}
	})
}

export class Api {

	constructor(routes, baseUrl) {
		this._axios = getAxiosInstance(baseUrl)
		this._routes = routes
		this._cancelTokens = {}
	}

	async touch(handle, payload) {
		const [method, args] = this._parseArgs(handle, payload)
		const [err, result] = await toAsync(this._axios[method](...args))
		return [err, result]
	}

	_getRoute(handle) {
		for (const method of Object.keys(this._routes)) {
			const route = this._routes[method][handle]
			if (route) return [method, route]
		}
	}

	_cancelPending(handle) {
		if (this._cancelTokens[handle]) this._cancelTokens[handle].cancel('Cancel Error')
		this._cancelTokens[handle] = CancelToken.source()
		return this._cancelTokens[handle].token
	}

	_parseArgs(handle, payload) {
		const args = []
		const options = { cancelToken: this._cancelPending(handle) }
		const [method, template] = this._getRoute(handle)
		let route = template
		if (payload) {
			if (isPlainObject(payload)) {
				route = fill(template, payload).replace('/null', '')
				payload = diff(template, payload)
			}
			if (method === 'get') options.params = payload
		}
		if (method === 'get' || method === 'delete') args.push(route, options)
		else if (method === 'post' || method === 'put') args.push(route, payload, options)
		return [method, args]
	}
}

export const buildApi = (routes, baseUrl) => new Api(routes, baseUrl)