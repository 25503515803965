<template>
<div class='confirmHangUp' @click='$emit("cancel")'><div>
	<header>Are you sure you want to end the session?</header>
	<div class='controls'>
		<div class='cancel' @click='$emit("cancel")'>Cancel</div>
		<div class='submit' @click='$emit("submit")'>End Session</div>
	</div>
</div></div>
</template>

<script>
export default {
	name: 'ConfirmHangUp',
	methods: {
		onCancel() { this.$emit('cancel') },
		onSubmit() { this.$emit('submit') }
	}
}
</script>

<style lang='scss'>
.confirmHangUp {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 20;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(#000, .3);

	> div {
		width: 50%;
		max-width: 500px;
		min-width: 300px;
		border-radius: 6px;
		box-shadow: $box-shadow-lifted;
		background: $color-white;
		overflow: hidden;

		header {
			font-size: 20px;
			color: $color-primary-accent;
			text-align: center;
			padding: 30px 30px;
			padding-top: 40px;
		}

		.controls {
			display: flex;
			margin: 10px 30px 30px 30px;
			> div { flex: 1; }

			.submit, .cancel {
				padding: 30px 0;
				font-size: 20px;
				text-align: center;
				cursor: pointer;
				border-radius: 2px;
			}

			.submit {
				background: $button-gradient-primary;
				color: #fff;			
			}

			.cancel {
				background: $color-neutral-accent;
			}
		}
	}
}
</style>